import React, { useEffect } from 'react'
import queryString from 'query-string'
import axios from 'axios'
import FullPageLoader from '../elements/FullPageLoader'

/**
 * Entry point for the sign up with Xero flow. This page displays a simple
 * full page loading state while we get details of where to redirect the
 * user to at Xero. Once the user approves the Loaded app at Xero they will
 * be redirected back to the normal sign up page where we have additional
 * code that handles completing the exchange of user details to prepopulate
 * the sign up form.
 *
 * @param {*} param0 
 * @returns 
 */
const SignUpXeroTemplate = ({ location }) =>  {

  useEffect(() => {
    // Grab the url we should redirect the user to for them to authorise us
    // to get their details.
    axios
      .get(`${process.env.GATSBY_APIURL}/r/XeroOpenId`)
      .then(response => {
        const authUrl = response?.data?.authUrl;
        if (authUrl) {

          const xeroAuthUrl = new URL(authUrl.replace('loaded_domain', window.location.host));
          const { redirect_uri: redirectUri } = queryString.parse(xeroAuthUrl.search);

          window.localStorage.setItem('@@loaded/xeroOAuthOrigin', redirectUri);

          // redirect...
          window.location = xeroAuthUrl.href;
        }
    })
  }, [])

  return <FullPageLoader />
}

export default SignUpXeroTemplate